import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { convertToSlug } from "@utils";
import Stack from "@components/Stack";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { opacity: 0, y: -3, scale: 0.85, skew: -5 },
  show: { opacity: 1, y: 0, scale: 1, skew: 0 }
};

const query = graphql`
  {
    allStrapiStudy(sort: { order: ASC, fields: order }) {
      nodes {
        title
        company
        strapiId
        logo {
          publicURL
        }
      }
    }
  }
`;

function CaseStudyRows() {
  const { allStrapiStudy } = useStaticQuery(query);

  const studies = allStrapiStudy.nodes;

  return (
    <CaseStudyGroup
      key="logos"
      variants={container}
      initial="hidden"
      animate="show"
      exit="hidden"
    >
      {studies.map((study: any) => (
        <CaseStudy
          key={study.strapiId}
          variants={item}
          type="column"
          center
          whileHover={{ scale: 1.05 }}
          onClick={() => {
            navigate(`/work/${convertToSlug(study.title)}`);
          }}
        >
          {/* <CaseStudyTitle>{study.title}</CaseStudyTitle> */}
          <CaseStudyLogoContainer>
            <CaseStudyLogo>
              <img src={study.logo.publicURL} />
            </CaseStudyLogo>
          </CaseStudyLogoContainer>
        </CaseStudy>
      ))}
    </CaseStudyGroup>
  );
}

export default CaseStudyRows;

const Wrapper = styled(Stack)`
  width: 100%;
  position: relative;
  z-index: 3;

  @media (max-width: 950px) {
    grid-auto-flow: row;
  }
`;

const CaseStudyGroup = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 3rem;
`;

const CaseStudy = styled(Stack)`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  filter: grayscale(1);
  transition: filter 0.3s ease-in-out;
  grid-auto-rows: auto minmax(0, 1fr);

  :hover {
    filter: none;
  }
`;

const CaseStudyLogoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CaseStudyLogo = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    display: block;
    max-width: 200px;
    max-height: 125px;
  }
`;

const CaseStudyTitle = styled(motion.p)`
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  color: hsl(0 0% 35%);
  text-align: center;
  white-space: nowrap;
`;
