import React from "react";
import styled from "styled-components";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import PageWrapper from "@components/PageWrapper";
import SEO from "@components/SEO";
import CaseStudyRows from "@sections/CaseStudyRows";
import { motion } from "framer-motion";
import Stack from "@components/Stack";

const query = graphql`
  {
    strapiWorkPage {
      Main_additional_text
      Main_header_text
    }
  }
`;

function WorkPage({ location }: PageProps) {
  const { strapiWorkPage } = useStaticQuery(query);
  const { Main_additional_text, Main_header_text } = strapiWorkPage;

  return (
    <PageWrapper>
      <SEO title="Our Work" pathname={location.pathname} />

      <Wrapper>
        <Stack type="column" gap={3} stretchColumns>
          <TopText>{Main_header_text}</TopText>
          {/* <DescriptionText>{Main_additional_text}</DescriptionText> */}
        </Stack>
        <CaseStudyRows />
      </Wrapper>
      {/* <Background /> */}
    </PageWrapper>
  );
}

export default WorkPage;

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-flow: row;
  grid-gap: 8rem;
  margin: 24vh auto 14vh auto;
  position: relative;
  /* border: 3px solid green; */
  padding: 0 4rem;

  @media (max-width: 950px) {
  }
`;

const TopText = styled.h1`
  font-weight: 300;
  line-height: 1;
  color: hsl(0 0% 1%);
  text-align: center;

  span {
    color: hotpink;
    font-style: italic;
  }
`;

const DescriptionText = styled.div`
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  color: hsl(0 0% 30%);
  /* max-width: 70ch; */
`;
